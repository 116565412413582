<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attr }">
      <v-row no-gutters>
        <v-col cols="12">
          <v-row align="center" class="text-start" justify="center" no-gutters>
            <v-col>
              <v-list-item v-bind="attr" v-on.native="on">
                <v-list-item-avatar>
                  <v-img :alt="`${inspection.site.name} logo`" :aspect-ratio="16/9" :src="inspection.site.logo"
                         contain/>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="`<b>${inspection.site.name}</b>`"/>
                  <v-list-item-subtitle v-text="inspection.site.category.name"/>
                  <v-list-item-subtitle v-text="`${inspection.branch.brand_name} #${inspection.branch.number}`"/>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-subtitle
                    v-html="`<b>${$vuetify.lang.t('$vuetify.general.inspection.assignee')}</b> <span class=${inspectionNameColor}>${inspectorName}</span>`"/>
                  <v-list-item-subtitle v-html="`<b>${$vuetify.lang.t('$vuetify.general.inspection.dueDate')}</b> ${inspection.inspection_due_date}`"/>
                  <v-list-item-subtitle v-html="`<b>${$vuetify.lang.t('$vuetify.general.inspection.type')}</b> ${inspection.type}`"/>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col v-if="showSelectButton" cols="auto">
              <v-checkbox @change="$emit('check-changed', !!$event)"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <slot name="divider"/>
        </v-col>
      </v-row>
    </template>
    <kurcc-inspection-list-item-dialog-content :inspection="inspection" @change="$emit('change')"
                                               @close-dialog="dialog = false"/>
  </v-dialog>
</template>

<script>
import Inspection from '@/modules/inspections/models/inspection'

export default {
  name: 'KurccInspectionListItem',
  components: {
    KurccInspectionListItemDialogContent: () => import('@/modules/app/components/KurccInspectionListItemDialogContent')
  },
  props: {
    inspection: {
      type: Inspection,
      required: true
    },
    showSelectButton: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    inspectionNameColor () {
      return this.inspectionHaveInspector ? 'success--text' : 'error--text'
    },
    inspectionAssignedToMe () {
      return this.$auth.user().id === this.inspection.inspector.id
    },
    inspectorFullName () {
      return `${this.inspection.inspector.first_name} ${this.inspection.inspector.last_name}`
    },
    inspectionHaveInspector () {
      return !!this.inspection.inspector
    },
    inspectorName () {
      if (this.inspectionHaveInspector) {
        if (this.inspectionAssignedToMe) {
          return 'You'
        } else {
          return this.inspectorFullName
        }
      } else {
        return 'Not assigned'
      }
    }
  }
}
</script>
